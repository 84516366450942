@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply relative bg-whiten text-base font-normal text-body;
    @apply font-alexandria;
  }
}

.ui-pagination > * {
  @apply flex h-8 min-w-[32px] items-center justify-center rounded px-2 text-sm font-medium hover:bg-black/5 disabled:pointer-events-none disabled:opacity-50 data-[active]:bg-primary/90 data-[active]:text-white data-[active]:hover:bg-primary/80 dark:hover:bg-white/5 data-[active]:dark:hover:bg-primary/70;
}
.container {
  @apply mx-auto max-w-[85rem] px-2 md:px-8 lg:px-20 xl:px-20;
}
.paddings {
  @apply md:py-4 lg:py-4;
}
.paddings2 {
  @apply md:px-2 2xl:px-4;
}
.ltr {
  direction: ltr !important;
}
.rtl {
  direction: rtl !important;
}

.ui-pagination {
  @apply flex;
}
.ui-pagination > * > svg {
  @apply -mx-2;
}

input[type="time"]::-webkit-clear-button {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  margin: 0 !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  margin: 0 !important;
}
input[type="time"]::-webkit-datetime-edit-ampm-field {
  display: none;
}

.tooltip {
  @apply relative inline-block cursor-pointer;
}

.tooltip .tooltiptext {
  @apply invisible absolute bottom-full left-1/2 z-10 mb-2 w-32 -translate-x-1/2 transform rounded bg-primary/70 py-1 text-center text-white opacity-0 transition-opacity duration-200;
}

.tooltip:hover .tooltiptext {
  @apply visible opacity-100;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #38b2ac;
  border-radius: 9999px;
}

::-webkit-scrollbar-track {
  background-color: #e2e8f0;
  border-radius: 9999px;
}

::-webkit-scrollbar-button {
  display: none;
}
